import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Button, Input, Select, Radio, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { doc, getDoc, setDoc } from 'firebase/firestore'

import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { billingCoin } from '../../const/billing'

import './Dashboard.css'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()
  const [btnLoading, setBtnLoading] = useState(false)
  const { bid } = useParams()

  const [billingForm] = AntdForm.useForm()

  const { value, loading } = useAsync(async () => {
    const snap = await getDoc(doc(db, 'billings', bid))
    return {
      ...snap.data(),
      id: snap.id
    }
  }, [bid])

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      await setDoc(doc(db, 'billings', bid), {
        ap: formData.ap,
        r: formData?.r ?? ''
      }, {
        merge: true
      })
      setBtnLoading(false)
      navigate('/admin/dashboard/billings')
    } catch (error) {
      setBtnLoading(false)
    }
  }

  if (loading) return <Skeleton></Skeleton>
  return (
    <div className='dashboard-page'>
      <Card
        title='Payment'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <AntdForm
          labelCol={{ span: 4 }}
          onFinish={handleSubmit}
          initialValues={value}
          form={billingForm}
          name='billingForm'
        >
          <AntdForm.Item name='c' label='Coin'>
            <Select
              placeholder='Select Coin'
              disabled
            >
              {((billingCoin || []).map((o) => (
                <Select.Option key={o.value} value={o.value}>{o.name}</Select.Option>
              )))
              }
            </Select>
          </AntdForm.Item>
          <AntdForm.Item name='from' label='Address'>
            <Input disabled />
          </AntdForm.Item>
          <AntdForm.Item name='am' label='Amount'>
            <InputNumberNew
              disabled
            />
          </AntdForm.Item>
          <AntdForm.Item name='r' label='Remark'>
            <Input.TextArea />
          </AntdForm.Item>
          <AntdForm.Item name='ap' label='Approval' rules={[{ required: true, message: 'Please Select Approval' }]}>
            <Radio.Group
              options={[
                { label: 'Approve', value: 'approved' },
                { label: 'Reject', value: 'rejected' }
              ]}
              optionType='button'
              buttonStyle='solid'
            />
          </AntdForm.Item>
          <div style={{ textAlign: 'center' }}>
            <Button loading={btnLoading} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 20 }} onClick={() => billingForm.submit()} type='primary'><SaveOutlined />Submit</Button>
            <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
          </div>
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form
