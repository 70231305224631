import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Card, Table, Button, Popconfirm, Skeleton } from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

import { onSnapshot, collection, doc, deleteDoc, query, where } from 'firebase/firestore'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'

import './Setting.css'

const TokenList = () => {
  const navigate = useNavigate()
  const { db, uid } = useAuth()
  const { getPageNo, onPageChange } = useLayoutContext()

  const [value, setValue] = useState([])

  useEffect(() => {
    const unsub = onSnapshot(query(collection(db, 'tokens'), where('uid', '==', uid)), { includeMetadataChanges: true }, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id
        }
      })
      setValue(data)
    })
    return () => unsub()
  }, [db, uid])

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'tokens', id))
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Operation',
      dataIndex: 'Operation',
      width: 250,
      render: (_, row) => (
        <div>
          <Button style={{ borderRadius: '4px' }} type='primary' size='middle' onClick={() => navigate(`/admin/settings/tokens/${row.id}/edit`)}><EditOutlined /></Button>
          <Popconfirm
            title={`Are you sure to delete ${row.name} token?`}
            onConfirm={() => handleDelete(row.id)}
            okText='Yes'
            cancelText='No'
          >
            <Button style={{ borderRadius: '4px', marginLeft: '16px' }} type='primary' size='middle' danger><DeleteOutlined /></Button>
          </Popconfirm>
        </div>
      )
    }
  ]

  if (!value) return <Skeleton></Skeleton>
  return (
    <div className='setting-page'>
      <Card
        title='Alert Token List'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button style={{ borderRadius: '4px' }} onClick={() => navigate('/admin/settings/tokens/create')} type='primary'><PlusOutlined />Add New Alert Token</Button>
        </div>
        <Table
          bordered
          rowKey='id'
          columns={columns}
          dataSource={value}
          pagination={{
            total: value?.length ?? 0,
            current: getPageNo('tokenList'),
            onChange(page) {
              onPageChange('tokenList', page)
            }
          }}
        />
      </Card>
    </div>
  )
}

export default TokenList
