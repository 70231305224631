import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Button, Input, Select, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { httpsCallable } from 'firebase/functions'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { pickBy } from '../../utils/tools'
import { getExLogo } from '../../utils/tools'
import { exchanges } from '../../const/bots'

import './Setting.css'

const Form = () => {
  const navigate = useNavigate()
  const { functions, uid, tester } = useAuth()
  const { setData } = useLayoutContext()
  const { kid } = useParams()
  const [btnLoading, setBtnLoading] = useState(false)
  const [exchange, setExchange] = useState()

  const [keyForm] = AntdForm.useForm()

  const mode = kid ? 'Edit' : 'Create'

  const { value, loading } = useAsync(async () => {
    if (!kid) {
      return {
        uid: uid
      }
    }

    const getKey = httpsCallable(functions, 'getKey-getKey')
    const { data } = await getKey({ uid: uid, kid: kid })
    setExchange(data.ex)
    return {
      ...data,
      secret: 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
      password: (data.ex === 'okx') ? 'xxxxxxxxxxxxxxxx' : null
    }
  }, [kid])

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      const updateKey = httpsCallable(functions, 'updateKey-updateKey')
      const data = pickBy(formData)
      if (kid) {
        delete data.key
        delete data.secret
        delete data.password
        delete data.ksi
        await updateKey({ uid: uid, kid: kid, data: data })
      } else {
        await updateKey({ uid: uid, data: data })
      }
      setData('keys', null)
      setBtnLoading(false)
      navigate('/admin/settings/keys')
    } catch (error) {
      setBtnLoading(false)
    }
  }

  const onExchangeChange = (value) => {
    setExchange(value)
    const exchange = exchanges.find((o) => o.value === value)
    keyForm.setFieldsValue({
      ...keyForm.getFieldsValue(),
      ksi: exchange.ksi
    })
  }

  if (loading) return <Skeleton></Skeleton>
  return (
    <div className='setting-page'>
      <Card
        title={`${mode} API Key`}
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <AntdForm
          labelCol={{ span: 4 }}
          onFinish={handleSubmit}
          initialValues={value}
          form={keyForm}
          name='keyForm'
        >
          <AntdForm.Item name='uid' hidden={true}>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item name='ksi' hidden={true}>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item name='name' label='Name' rules={[{ required: true, message: 'Please input Name!' }]}>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item name='ex' label='Exchange' rules={[{ required: true, message: 'Please select Exchange!' }]}>
            <Select
              placeholder='Select Exchange'
              onChange={(v) => onExchangeChange(v)}
              disabled={mode === 'Edit'}
            >
              {(exchanges.filter((o) => (!o.test || (o.test && tester))).map((o) => (
                <Select.Option key={o.value} value={o.value}>{getExLogo(o.value, 64)}</Select.Option>
              )))
              }
            </Select>
          </AntdForm.Item>
          <AntdForm.Item name='key' label='API Key' rules={[{ required: true, message: 'Please input API Key!' }]}>
            <Input disabled={mode === 'Edit'} />
          </AntdForm.Item>
          <AntdForm.Item name='secret' label='API Secret' rules={[{ required: true, message: 'Please input API Secret!' }]}>
            <Input.Password disabled={mode === 'Edit'} />
          </AntdForm.Item>
          {(exchange === 'okx') && (
            <AntdForm.Item name='password' label='Passphrase' rules={[{ required: true, message: 'Please input Passphrase!' }]}>
              <Input.Password disabled={mode === 'Edit'} />
            </AntdForm.Item>
          )}
          <div style={{ textAlign: 'center' }}>
            <Button loading={btnLoading} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 20 }} onClick={() => keyForm.submit()} type='primary'><SaveOutlined />Submit</Button>
            <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
          </div>
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form
