import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Form as AntdForm, Card, Button, Input, Select, Radio } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { onSnapshot, doc, setDoc, collection, query, where, limit } from 'firebase/firestore'

import InputNumberNew from '../../components/InputNumberNew'

import { useAuth } from '../../contexts/AuthContextProvider'
import { billingCoin } from '../../const/billing'

import './Dashboard.css'

const Form = () => {
  const navigate = useNavigate()
  const { db } = useAuth()

  const [value, setValue] = useState()
  const [btnLoading, setBtnLoading] = useState(false)

  const [billingForm] = AntdForm.useForm()

  useEffect(() => {
    const unsub = onSnapshot(query(collection(db, 'billings'), where('s', '==', 'pending'), where('c', 'in', ['USDT', 'BUSD']), limit(1)), { includeMetadataChanges: true }, (snapshot) => {
      const [data] = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id
        }
      })
      setValue(data)
    })
    return () => unsub()
  }, [db])

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      await setDoc(doc(db, 'billings', formData.id), {
        ap: formData.ap,
        r: formData?.r ?? ''
      }, {
        merge: true
      })
      setBtnLoading(false)
    } catch (error) {
      setBtnLoading(false)
    }
  }

  if (!value) return (
    <div style={{ textAlign: 'center' }}>
      <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
    </div>
  )
  return (
    <div className='dashboard-page'>
      <Card
        title='Billing Approval'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <AntdForm
          labelCol={{ span: 4 }}
          onFinish={handleSubmit}
          initialValues={value}
          form={billingForm}
          name='billingForm'
        >
          <AntdForm.Item name='id' hidden={true}>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item name='c' label='Coin'>
            <Select
              placeholder='Select Coin'
              disabled
            >
              {((billingCoin || []).map((o) => (
                <Select.Option key={o.value} value={o.value}>{o.name}</Select.Option>
              )))
              }
            </Select>
          </AntdForm.Item>
          <AntdForm.Item name='from' label='Address'>
            <Input disabled />
          </AntdForm.Item>
          <AntdForm.Item name='am' label='Amount'>
            <InputNumberNew
              disabled
            />
          </AntdForm.Item>
          <AntdForm.Item name='r' label='Remark'>
            <Input.TextArea />
          </AntdForm.Item>
          <AntdForm.Item name='ap' label='Approval' rules={[{ required: true, message: 'Please Select Approval' }]}>
            <Radio.Group
              options={[
                { label: 'Approve', value: 'approved' },
                { label: 'Reject', value: 'rejected' }
              ]}
              optionType='button'
              buttonStyle='solid'
            />
          </AntdForm.Item>
          <div style={{ textAlign: 'center' }}>
            <Button loading={btnLoading} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 20 }} onClick={() => billingForm.submit()} type='primary'><SaveOutlined />Submit</Button>
            <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
          </div>
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form
