import { tickers } from '../const/tickers'

const getId = (ex, type, tk) => (`${ex}${type}${tk}`.toLowerCase()).replace(/[-_/]/g, '')

const getExponent = (value) => {
  const v = parseFloat(value)
  const l = Math.log10(v)
  if (Math.floor(l) !== l) {
    const rl = Math.floor(l)
    return {
      exp: (rl === 0) ? 0 : rl * (-1),
      man: Math.floor(v / Math.pow(10, rl)),
    }
  }
  return {
    exp: (l === 0) ? 0 : l * (-1),
    man: 1,
  }
}

const getTickers = async (exchange, type) => {
  try {
    let result = []
    if (exchange === 'binance') {
      if (type === 'spot') {
        const data = await fetch('https://api.binance.com/api/v3/exchangeInfo')
        const jsonData = await data.json()
        result = jsonData.symbols.map((o) => {
          const p = o.filters.find((o) => o.filterType === 'PRICE_FILTER')
          const l = o.filters.find((o) => o.filterType === 'LOT_SIZE')
          const pdec = getExponent(p.tickSize)
          const sdec = getExponent(l.stepSize)
          return {
            tk: o.symbol,
            balas: o.quoteAsset,
            trdas: o.baseAsset,
            scnt: sdec.man,
            pdec: pdec.exp,
            sdec: sdec.exp,
          }
        })
      } else if (type === 'futures') {
        const data = await fetch('https://fapi.binance.com/fapi/v1/exchangeInfo')
        const jsonData = await data.json()
        result = jsonData.symbols.map((o) => ({
          tk: o.symbol,
          balas: o.quoteAsset,
          trdas: o.baseAsset,
          scnt: 1,
          pdec: o.pricePrecision,
          sdec: o.quantityPrecision,
        }))
      } else if (type === 'delivery') {
        const data = await fetch('https://dapi.binance.com/dapi/v1/exchangeInfo')
        const jsonData = await data.json()
        result = jsonData.symbols.map((o) => ({
          tk: o.symbol,
          balas: o.baseAsset,
          trdas: o.quoteAsset,
          scnt: o.contractSize,
          pdec: o.pricePrecision,
          sdec: o.quantityPrecision,
        }))
      }
    } else if (exchange === 'okx') {
      if (type === 'spot') {
        const data = await fetch('https://www.okx.com/api/v5/public/instruments?instType=SPOT')
        const jsonData = await data.json()
        result = jsonData.data.map((o) => {
          const pdec = getExponent(o.tickSz)
          const sdec = getExponent(o.minSz)
          return {
            tk: o.instId,
            balas: o.quoteCcy,
            trdas: o.baseCcy,
            scnt: sdec.man,
            pdec: pdec.exp,
            sdec: sdec.exp,
          }
        })
      } else if (type === 'futures') {
        const data = await fetch('https://www.okx.com/api/v5/public/instruments?instType=FUTURES')
        const jsonData = await data.json()
        result = jsonData.data.map((o) => {
          const pdec = getExponent(o.tickSz)
          const sdec = getExponent(o.minSz)
          return {
            tk: o.instId,
            balas: o.settleCcy,
            trdas: o.ctValCcy,
            scnt: parseFloat(o.ctVal),
            pdec: pdec.exp,
            sdec: sdec.exp,
          }
        })
      } else if (type === 'swap') {
        const data = await fetch('https://www.okx.com/api/v5/public/instruments?instType=SWAP')
        const jsonData = await data.json()
        result = jsonData.data.map((o) => {
          const pdec = getExponent(o.tickSz)
          const sdec = getExponent(o.minSz)
          return {
            tk: o.instId,
            balas: o.settleCcy,
            trdas: o.ctValCcy,
            scnt: parseFloat(o.ctVal),
            pdec: pdec.exp,
            sdec: sdec.exp,
          }
        })
      }
      // } else if (exchange === 'bitkub') {
      //   const data = await fetch('https://api.bitkub.com/api/market/symbols')
      //   const jsonData = await data.json()
      //   result = jsonData.result.map((o) => {
      //     const sym = o.symbol.split('_')
      //     return {
      //       tk: `${sym[1]}/${sym[0]}`,
      //       balas: sym[0],
      //       trdas: sym[1],
      //       scnt: 1,
      //       pdec: 2,
      //       sdec: 8,
      //     }
      //   })
    }
    return Promise.resolve(result)
  } catch (error) {
    return Promise.resolve(tickers[exchange][type])
  }
}

export {
  getId,
  getExponent,
  getTickers
}
