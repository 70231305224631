const rate = 0.01

const timeFrame = {
  tv: [
    { id: 0, value: '1m', name: '1 Minute', rate: 48 },
    { id: 1, value: '3m', name: '3 Minutes', rate: 36 },
    { id: 2, value: '5m', name: '5 Minutes', rate: 27 },
    { id: 3, value: '15m', name: '15 Minutes', rate: 18 },
    { id: 4, value: '30m', name: '30 Minutes', rate: 12 },
    { id: 5, value: '45m', name: '45 Minutes', rate: 9 },
    { id: 6, value: '1h', name: '1 Hour', rate: 6 },
    { id: 7, value: '2h', name: '2 Hours', rate: 6 },
    { id: 8, value: '3h', name: '3 Hours', rate: 6 },
    { id: 9, value: '4h', name: '4 Hours', rate: 6 },
    { id: 10, value: '6h', name: '6 Hours', rate: 5 },
    { id: 11, value: '8h', name: '8 Hours', rate: 5 },
    { id: 12, value: '12h', name: '12 Hours', rate: 4 },
    { id: 13, value: '1d', name: '1 Day', rate: 3 },
    { id: 14, value: '2d', name: '2 Days', rate: 3 },
    { id: 15, value: '1w', name: '1 Week', rate: 2 },
    { id: 16, value: '2w', name: '2 Weeks', rate: 2 },
    { id: 17, value: '1mo', name: '1 Month', rate: 1 },
    { id: 18, value: '2mo', name: '2 Months', rate: 1 }
  ]
}

const botType = [
  { value: 'tv', name: 'TradingView Bot' }
]

const exchanges = [
  {
    value: 'binance',
    name: 'Binance',
    ksi: 1,
    test: false,
    tv: {
      apiUri: 'https://bot.tvtradingbot.com/ex/binance/tv',
      apiTypes: [
        { value: 'spot', name: 'Spot' },
        { value: 'futures', name: 'USD-M Futures' },
        { value: 'delivery', name: 'Coin-M Futures' }
      ]
    },
    balas: {
      spot: ['USDT', 'BUSD', 'USDC', 'TUSD', 'DAI', 'BTC', 'ETH', 'BNB', 'AUD', 'EUR', 'GBP', 'BRL', 'BIDR', 'RUB', 'TRY', 'IDRT', 'PAX', 'UAH', 'NGN', 'VAI', 'TRX', 'BVND', 'XRP'],
      futures: ['USDT'],
      delivery: ['BTC', 'ETH', 'BNB']
    }
  },
  {
    value: 'okx',
    name: 'OKX',
    ksi: 1,
    test: false,
    tv: {
      apiUri: 'https://bot.tvtradingbot.com/ex/okx/tv',
      apiTypes: [
        { value: 'spot', name: 'Spot' },
        { value: 'futures', name: 'Futures' },
        { value: 'swap', name: 'Perpetual Swap' }
      ]
    },
    balas: {
      spot: ['USDT', 'USDC', 'USDK', 'DAI', 'BTC', 'ETH', 'OKB'],
      futures: ['USDT', 'USDC', 'USDK', 'DAI', 'BTC', 'ETH', 'OKB'],
      swap: ['USDT', 'USDC', 'USDK', 'DAI', 'BTC', 'ETH', 'OKB']
    }
  },
  // {
  //   value: 'bitkub',
  //   name: 'bitkub',
  //   test: true,
  //   tv: {
  //     apiUri: 'https://bot.tvtradingbot.com/ex/bitkub/tv',
  //     apiTypes: [
  //       { value: 'spot', name: 'Spot' }
  //     ]
  //   },
  //   balas: {
  //     spot: ['THB']
  //   }
  // }
]

const dayOfWeek = [
  { value: 0, name: 'Sunday' },
  { value: 1, name: 'Monday' },
  { value: 2, name: 'Tuesday' },
  { value: 3, name: 'Wednesday' },
  { value: 4, name: 'Thursday' },
  { value: 5, name: 'Friday' },
  { value: 6, name: 'Saturday' }
]

const tickerColor = {
  spot: '#2db7f580',
  futures: '#87d068bf',
  delivery: '#e89808bf',
  swap: '#e89808bf'
}

const threshold = {
  0: 20,
  1: 50,
  2: 100,
  3: 200,
  4: 500,
  5: 1000,
  6: 2000,
  7: 5000,
  8: 10000,
  9: 20000,
  10: 50000,
  11: 100000
}

// const pending = 7
const pending = 3 // Test Weekly

export {
  rate,
  timeFrame,
  botType,
  exchanges,
  dayOfWeek,
  tickerColor,
  threshold,
  pending
}
